import { render, staticRenderFns } from "./FormAuthorityConfig.vue?vue&type=template&id=b0e5f882&scoped=true"
import script from "./FormAuthorityConfig.vue?vue&type=script&lang=js"
export * from "./FormAuthorityConfig.vue?vue&type=script&lang=js"
import style0 from "./FormAuthorityConfig.vue?vue&type=style&index=0&id=b0e5f882&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0e5f882",
  null
  
)

export default component.exports